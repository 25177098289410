<template>
  <div>
    <form
      v-if="!submitted"
      :class="form.class"
      @submit.prevent="submit"
    >
      <p :class="form.welcomeMessage.class">
        {{ $t('login.verification.enter_email') }}
      </p>

      <q-input
        v-model="email"
        :class="form.email.class"
        :dark="mobileAndKinto ? null : form.email.dark"
        :filled="form.email.filled || null"
        square
        :color="mobileAndKinto ? 'black' : form.email.color"
        type="email"
        input-style="text-transform:none"
      />
      <q-btn
        :color="form.resetButton.color || 'login-green'"
        :size="form.resetButton.size || 'lg'"
        :class="form.resetButton.class"
        :align="form.resetButton.align || 'center'"
        :flat="form.resetButton.flat || null"
        :disable="disabled"
        @click="submit"
      >
        Send
        <q-icon v-if="partner.pages.login.form.signIn.iconRight" align-right :name="partner.pages.login.form.signIn.iconRight" />
      </q-btn>
    </form>

    <m-banner
      v-if="submitted"
      :title="$t('login.verification.email_sent')"
      :message="$t('login.verification.check_your_email')"
    />

    <q-btn
      v-if="submitted"
      :class="form.backButton.class"
      :align="form.backButton.align || 'center'"
      :flat="form.backButton.flat || null"
      type="submit"
      :size="form.backButton.size || 'md'"
      :unelevated="form.backButton.unelevated || null"
      to="/login"
      no-caps
    >
      {{ $t('back_to_sign_in') }}
    </q-btn>
  </div>
</template>

<script>

import { verify } from 'api/users'
import { required, email } from 'vuelidate/lib/validators'
import { MBanner } from 'components/'
import { mapGetters } from 'vuex'

export default {
  components: {
    MBanner
  },
  data () {
    return {
      email: null,
      submitted: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    disabled () {
      return this.$v.$invalid
    },
    form () {
      return this.partner.pages.password.email.form
    },
    mobileAndKinto () {
      return this.mobile && this.partner.name === 'Kinto'
    }
  },
  methods: {
    async submit () {
      if (!this.$v.$error) {
        try {
          await verify(this.email)
          this.submitted = true
        } catch (err) {
          this.$q.notify(this.$t('error.default'))
        }
      }
    }
  },
  validations: {
    email: { required, email }
  }
}
</script>

<style lang="stylus">
@import '../../../styles/login.styl'
</style>

<template>
  <div :class="mobileAndKinto ? 'login-mobile' : null">
    <form :class="form.class">
      <p :class="form.welcomeMessage.class">
        {{ form.welcomeMessage.text || $t('login.sign_up') }}
      </p>
      <q-input
        v-model="full_name"
        :label="$t('name.full')"
        :error="$v.last_name.$error"
        :dark="mobileAndKinto ? null : form.name.dark"
        :color="form.name.color"
        :flat="form.name.flat || null"
        :filled="form.name.filled || null"
        square
        :class="form.name.class"
        input-style="text-transform:none"
        :label-color="form.name.labelColor || null"
        :error-message="$t('login.validation.last_name')"
        @blur="$v.last_name.$touch()"
      />

      <q-input
        v-model="email"
        :label="$t('email')"
        :dark="mobileAndKinto ? null : form.email.dark"
        :color="form.email.color"
        :flat="form.email.flat || null"
        :filled="form.email.filled || null"
        :error="$v.email.$error"
        :error-message="emailError"
        square
        :class="form.email.class"
        input-style="text-transform:none"
        :label-color="form.email.labelColor || null"
        @blur="$v.email.$touch()"
      />

      <q-input
        v-if="organisation"
        v-model="organisation_name"
        :error="$v.organisation_name.$error"
        :label="$t('organisation.name')"
        :dark="mobileAndKinto ? null : form.organisationName.dark"
        :color="form.organisationName.color"
        :flat="form.organisationName.flat || null"
        :filled="form.organisationName.filled || null"
        square
        :class="form.organisationName.class"
        input-style="text-transform:none"
        :label-color="form.organisationName.labelColor || null"
        @input="$v.organisation_name.$touch()"
      />

      <q-input
        v-model="password"
        :error="$v.password.$error"
        :error-message="$t('login.validation.password_reset')"
        :label="$t('password')"
        type="password"
        :dark="mobileAndKinto ? null : form.password.dark"
        :color="form.password.color"
        :flat="form.password.flat || null"
        :filled="form.password.filled || null"
        square
        :class="form.password.class"
        input-style="text-transform:none"
        :label-color="form.password.labelColor || null"
        @blur="$v.password.$touch()"
      />

      <q-input
        v-model="password_confirmed"
        :error="$v.password_confirmed.$error"
        :error-message="$t('login.validation.passwords_do_not_match')"
        :label="$t('confirm.password')"
        type="password"
        :dark="mobileAndKinto ? null : form.passwordConfirmation.dark"
        :color="form.passwordConfirmation.color"
        :flat="form.passwordConfirmation.flat || null"
        :filled="form.passwordConfirmation.filled || null"
        square
        :class="form.passwordConfirmation.class"
        input-style="text-transform:none"
        :label-color="form.passwordConfirmation.labelColor || null"
        @blur="$v.password_confirmed.$touch()"
      />

      <div v-if="errors" class="full-width">
        <m-banner
          v-for="(error, key) in errors.detail"
          :key="key"
          :title="$t('error.unable_to.create_account')"
          :message="errors.detail[key][0]"
          icon="error"
          color="negative"
        />
      </div>

      <q-btn
        no-caps
        :disabled="invalidForm"
        :loading="loading"
        :size="form.completeButton.size || null"
        :color="form.completeButton.color || null"
        :class="form.completeButton.class || null"
        :align="form.completeButton.align || 'center'"
        :flat="form.completeButton.flat || null"
        @click="submit"
      >
        {{ $t('complete_sign_up') }}
        <q-icon v-if="form.completeButton.iconRight" align-right :name="form.completeButton.iconRight" />
      </q-btn>
      <q-btn
        v-if="$q.platform.is.mobile"
        :size="form.skipLogin.size || null"
        :flat="form.skipLogin.flat || null"
        outline
        type="button"
        :class="form.skipLogin.class"
        no-caps
        :color="form.skipLogin.color || 'primary'"
        :align="form.skipLogin.align || 'center'"
        @click="skipLogin"
      >
        {{ $t('login.continue_as_guest') }}
        <q-icon v-if="form.skipLogin.iconRight" align-right :name="form.skipLogin.iconRight" />
      </q-btn>
      <span class="text-center full-width q-mt-md">
        {{ $t('login.already_a_member') }}
        <router-link class="text-bold" to="/login">
          {{ $t('login.login_here') }}
        </router-link>
      </span>

      <small class="col-12 block q-mt-md text-center text-small" v-html="$t('mobilleo_privacy_and_terms_signup')" />
    </form>
  </div>
</template>

<script>
import router from 'router'
import { required, minLength, sameAs, requiredIf, email } from 'vuelidate/lib/validators'
import { Dialog } from 'quasar'
import { has, debounce } from 'lodash'
import { view, register, complete } from 'api/signup'
import { mapGetters } from 'vuex'
import { MBanner } from 'components/'
import i18n from 'i18n'
import getPartnerFromHostname from 'utils/partner'
import { derbyEmailValidation } from 'utils/utils'
const partner = getPartnerFromHostname()
function memberExpiredTokenMessage () {
  Dialog.create({
    title: i18n.t('login.link_expired'),
    message: i18n.t('login.no_password_reset'),
    ok: i18n.t('reset_password'),
    cancel: i18n.t('login.log_in')
  })
    .then(() => router.push({ name: 'Reset Password' }))
    .catch(e => {})
}

function signupExpiredTokenMessage () {
  Dialog.create({
    title: i18n.t('error.default'),
    message: i18n.t('error.login_signup_cant_complete'),
    ok: i18n.t('report_problem')
  })
    .then(() => {
      window.location = 'https://www.mobilleo.com/resources/support/'
    })
    .catch(e => {})
}

export default {
  components: { MBanner },
  data () {
    return {
      org: null,
      loading: false,
      errors: null,
      email: null,
      organisation: null,
      full_name: null,
      first_name: null,
      last_name: null,
      password: null,
      password_confirmed: null,
      organisation_name: null,
      partnerName: partner.name,
      form: partner.pages.signup.complete.form,
      mobile: window.innerWidth < 640
    }
  },
  validations: {
    password: { required, minLength: minLength(8) },
    password_confirmed: { required, sameAsPassword: sameAs('password') },
    first_name: { required },
    last_name: { required },
    email: { required, email, derbyEmailValidation: (value, vm) => vm.isDerby ? derbyEmailValidation(value) : true },
    organisation_name: { required: requiredIf(model => {
      return model.organisation === true
    }) }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    invalidForm () {
      return this.$v.$invalid
    },
    mobileAndKinto () {
      return this.mobile && ['Kinto', 'DerbyGO'].includes(this.partnerName)
    },
    isDerby () {
      return this.$store.getters['partner'].slug === 'derbygo'
    },
    emailError () {
      if (!this.$v.email.email) {
        return this.$t('login.validation.email')
      } else if (!this.$v.email.derbyEmailValidation) {
        return this.$t('login.validation.incorrect_domain', { domain: '@derby.ac.uk and @unimail.derby.ac.uk', partner: 'DerbyGo' })
      }
      return ''
    }
  },
  watch: {
    full_name: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          const names = val.split(' ')
          this.first_name = names.shift()
          this.last_name = names.pop()
        }
      }
    }
  },
  methods: {
    submit: debounce(async function () {
      this.loading = true
      const payload = {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        password: this.password
      }
      if (this.organisation_name) {
        payload.organisation_name = this.organisation_name
      }

      try {
        if (!this.org) {
          await register(payload)
        } else {
          await complete(this.$route.query, payload, this.org)
        }

        this.$q.notify({
          message: this.$t('login.signed_up'),
          color: 'positive',
          icon: 'mdi-check'
        })
        this.$router.push('/login')
      } catch (err) {
        this.loading = false
        if (err.data.detail) {
          this.errors = err.data
        } else {
          this.errors = {
            detail: { unexpected: [this.$t('contact_us_phone')] }
          }
        }
      }
    }, 300),
    skipLogin () {
      this.submitted = true
      this.$store.dispatch('guestMode').then(({ status }) => {
        this.$ga('set', 'userId', this.$store.getters.user.username)
        this.$router.push(this.$route.query.redirect || 'dashboard', () => {
          this.$store.dispatch('addresses/getSavedLocations')
        })
      }).catch((err) => {
        if (err.data.message === 'User email is unverified') {
          this.errMessage = this.$t('login.unverified_email')
        } else {
          this.errMessage = this.$t('login.incorrect_details')
        }
        this.submitted = false
        this.invalid = true
      })
    }

  },
  beforeRouteEnter (to, from, next) {
    const org = to.params.org
    if (has(to.query, 'token') && has(to.query, 'expires') && has(to.query, 'signature')) {
      return view(to.query, org)
        .then(response => {
          next(vm => {
            vm.org = org
            vm.email = response.data.email
            vm.organisation = !!response.data.organisation

            if (response.meta) {
              vm.first_name = response.meta.first_name
              vm.last_name = response.meta.last_name
            }
          })
        })
        .catch(response => {
          next({ name: 'login' })
          if (to.path.includes('/signup/complete')) {
            signupExpiredTokenMessage()
          } else {
            memberExpiredTokenMessage()
          }
        })
    } else if (['kinto'].includes(partner.slug)) {
      next('/')
    } else {
      next()
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../../styles/login.styl'
  .bp-green
    color: #009640
</style>

<template>
  <div :class="mobileAndKinto ? 'login-mobile' : null">
    <form
      :class="form.class"
      @submit.prevent="attemptLogin"
    >
      <p v-if="form.welcomeMessage" :class="form.welcomeMessage.class">
        {{ form.welcomeMessage.text }}
      </p>
      <div class="col-12 row">
        <q-input
          v-model="login"
          :label="$t('email')"
          :label-color="form.email.labelColor || null"
          type="email"
          :dark="mobileAndKinto ? null : form.email.dark"
          :filled="form.email.filled || null"
          square
          autofocus
          clearable
          :color="mobileAndKinto ? 'black' : form.email.color"
          :class="form.email.class"
          input-style="text-transform:none"
        />

        <q-input
          v-model="password"
          :label="$t('input.password')"
          :label-color="form.password.labelColor || null"
          :type="isPwd ? 'password' : 'text'"
          :dark="mobileAndKinto ? null : form.password.dark"
          :filled="form.password.filled || null"
          square
          clearable
          :color="mobileAndKinto ? 'black' : form.password.color"
          :class="form.password.class"
          input-style="text-transform:none"
        >
          <template v-if="password" v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <q-btn
          :loading="submitted"
          :color="form.signIn.color || 'login-green'"
          :disable="disabled"
          type="submit"
          no-caps
          :size="form.signIn.size || null"
          :class="form.signIn.class"
          :align="form.signIn.align || 'center'"
          :flat="form.signIn.flat || null"
        >
          {{ form.signIn.text ? form.signIn.text : 'Log In' }}
          <q-icon v-if="form.signIn.iconRight" align-right :name="form.signIn.iconRight" />
          <q-spinner slot="loading" />
        </q-btn>

        <div class="verification">
          <small
            v-if="invalid"
            :class="form.validation.class"
          >
            {{ errMessage }}
          </small>
          <q-btn
            v-if="errMessage === $t('login.unverified_email')"
            :class="form.verificationButton.class"
            :color="form.verificationButton.color || 'login-green'"
            :align="form.verificationButton.align || 'center'"
            :flat="form.verificationButton.flat || null"
            @click="() => $router.push({ name: 'login-verification-resend' })"
          >
            {{ $t('login.verification.send_new') }}
            <q-icon v-if="form.signIn.iconRight" align-right :name="form.signIn.iconRight" />
          </q-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { requiredIf, email } from 'vuelidate/lib/validators'
import getPartnerFromHostname from 'utils/partner'
const partner = getPartnerFromHostname()

export default {
  data () {
    return {
      login: '',
      loginLower: '',
      password: '',
      isPwd: true,
      submitted: false,
      invalid: false,
      partnerName: partner.name,
      form: partner.pages.login.form,
      mobile: window.innerWidth < 640,
      errMessage: null
    }
  },
  computed: {
    disabled () {
      return this.$v.$invalid || !(!!this.password && !!this.login)
    },
    mobileAndKinto () {
      return this.mobile
    },
    isKinto () {
      return this.partnerName === 'Kinto'
    }
  },
  watch: {
    login (val) {
      this.login = val.trim().replace(' ', '')
      this.loginLower = this.login.toLowerCase()
    }
  },
  validations: {
    loginLower: {
      requiredIf,
      email
    },
    password: {
      requiredIf
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!to.meta.public) {
      this.$store.dispatch('resetUI').then(() => next())
    } else {
      next()
    }
  },
  methods: {
    attemptLogin () {
      this.submitted = true
      if (!this.$v.$error) {
        this.$store.dispatch('login', {
          username: this.login,
          password: this.password
        }).then(({ status }) => {
          this.$ga('set', 'userId', this.$store.getters.user.username)
          this.$router.push(this.$route.query.redirect || 'dashboard', () => {
            this.$store.dispatch('addresses/getSavedLocations')
          })
        }).catch((err) => {
          if (err.data.message === 'User email is unverified') {
            this.errMessage = this.$t('login.unverified_email')
          } else {
            this.errMessage = this.$t('login.incorrect_details')
          }
          this.submitted = false
          this.invalid = true
        })
      }
    },
    skipLogin () {
      this.submitted = true
      this.$store.dispatch('guestMode').then(({ status }) => {
        this.$ga('set', 'userId', this.$store.getters.user.username)
        this.$router.push(this.$route.query.redirect || 'dashboard', () => {
          this.$store.dispatch('addresses/getSavedLocations')
        })
      }).catch((err) => {
        if (err.data.message === 'User email is unverified') {
          this.errMessage = this.$t('login.unverified_email')
        } else {
          this.errMessage = this.$t('login.incorrect_details')
        }
        this.submitted = false
        this.invalid = true
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.space
  margin-top: 32px
.docs
  margin-top 15px
.doc
  text-decoration underline
@import '../../styles/login.styl'
</style>

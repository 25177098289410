<template>
  <div class="col-12 verification-complete">
    <div
      v-if="success"
    >
      <m-banner
        :title="$t('success')"
        :message="$t('login.verification.success')"
        icon="check_circle"
        color="light-blue-11"
      />
    </div>
    <div v-else>
      <m-banner
        :title="$tc('error.error')"
        :message="$t('login.verification.failure')"
        icon="fas fa-times-circle"
        color="light-blue-11"
      />
    </div>
    <q-btn
      :class="form.backButton.class"
      :align="form.backButton.align || 'center'"
      :flat="form.backButton.flat || null"
      type="submit"
      :size="form.backButton.size || 'md'"
      :unelevated="form.backButton.unelevated || null"
      to="/login"
      no-caps
    >
      {{ $t('back_to_sign_in') }}
    </q-btn>
  </div>
</template>
<script>
import { verify } from 'api/login'
import { mapGetters } from 'vuex'
import { MBanner } from 'components/'

export default {
  name: 'VerificationComplete',
  components: {
    MBanner
  },
  data () {
    return {
      success: false,
      form: null
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    })
  },
  created () {
    this.form = this.partner.pages.password.email.form
  },
  async beforeRouteEnter (to, from, next) {
    const { username, verification_code } = to.params
    try {
      await verify(username, verification_code)
      next(vm => {
        vm.success = true
      })
    } catch (err) {
      next(vm => {
        vm.success = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.verification-complete
  padding 0 5%
</style>
